<template>
  <div class="pagePadding">
    <div class="pageTop">
      <Form inline class="formMarginBtm20 clearfix">
        <FormItem>
          <span>发起仓库：</span>
          <Select class="iviewIptWidth250" v-model="postFrom.warehouse_id" @on-change="wareChange">
            <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
          </Select>
        </FormItem>
        <FormItem style="margin-left: 150px;">
          <span style="margin-right: 10px;">客户名称: </span><span> {{ CustomerName }}</span>
        </FormItem>
      </Form>
      <Table :columns="listColumns" :data="meterList" border @on-selection-change="selectChange" :height="500">
        <template slot-scope="{ row, index }" slot="product_model_code">
          <div v-if="type == 2">
            <div v-if="row.product_code_child_array.length > 1">
              <Select :placeholder="!postFrom.warehouse_id ? '请先选择仓库' : '请选择'" @on-change="changeCode($event, row, index)" v-model="row.product_model_code" :disabled="!postFrom.warehouse_id">
                <Option v-for="(item, subindex) in row.product_code_child_array" :value="item" :key="subindex">{{ item }}</Option>
              </Select>
            </div>
            <div class="imgInfo" v-else>
              <img v-if="row.transfer_tag.length != 0" :src="require('../../../assets/images/' + row.transfer_tag.join(',').replace(/,/gi, '') + '.png')" alt="" />
              <span>{{ row.product_model_code }}</span>
            </div>
          </div>
          <div class="imgInfo" v-else>
            <img v-if="row.transfer_tag.length != 0" :src="require('../../../assets/images/' + row.transfer_tag.join(',').replace(/,/gi, '') + '.png')" alt="" />
            <span>{{ row.product_model_code }}</span>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="product_unit_price">
          <div class="danjia">
            <span style="margin-left: 10px;" class="fl">¥ {{ row._check }}</span>
            <InputNumber :max="999999999.99" :min="0" :precision="2" placeholder="请输入" v-model="row.product_unit_price" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'product_unit_price', index)" :disabled="type == 1"></InputNumber>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="sale_tax_rate">
          <div class="danjia">
            <InputNumber :max="999999999.99" :min="0" :precision="2" placeholder="请输入" v-model="row.sale_tax_rate" :active-change="false" style="width: 100px;" @on-change="changeTotal($event, 'sale_tax_rate', index)" :disabled="type == 1"></InputNumber>
            <span style="margin-left: 10px;" class="fl">% {{ row._check }}</span>
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="product_amount">
          <InputNumber :max="999999" :min="1" :precision="0" placeholder="请输入" v-model="row.product_amount" :active-change="false" @on-change="changeTotal($event, 'product_amount', index)" :disabled="type == 1"></InputNumber>
        </template>
      </Table>
      <div style="position: relative; border: 1px solid #e8eaec; height: 500px; border-top: none !important;" class="clearfix noData" v-if="meterList.length == 0">
        <img style="margin-top: 100px;" src="../../../assets/images/tableNoData.png" />
      </div>
      <div class="summary" v-if="totleMoney > 0">
        <div>
          <span style="margin-right: 20px;"
            >总数量：<span class="color389">{{ allNum }}</span></span
          >
          <span>合计金额：</span>
          <span class="color389">¥{{ totleMoney }}</span>
        </div>
      </div>
      <div class="tipsDiv">
        <div class="tipsFl">备注</div>
        <div class="tipsFr">
          <i-input type="textarea" maxlength="100" show-word-limit placeholder="请输入" :disabled="type == 1" v-model="postFrom.remark"></i-input>
        </div>
      </div>
    </div>
    <div class="pageBtm clearfix">
      <span class="longTextBtn finger btnSure marginLeft20 fr" v-if="type == 2" @click="allocationTrue">转调拨单</span>
      <span class="longTextBtn finger btnSure marginLeft20 fr" v-if="type == 2" @click="post(2)">转为销售订单</span>
      <span class="longTextBtn finger btnSure marginLeft20 fr" v-if="type == 2" @click="post(1)">转为采购申请</span>
      <span class="pageBtn finger btnReset fr" @click="back">返回</span>
    </div>
    <!--    有产品数据时 切换别的供应商-->
    <Modal v-model="postStatus" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <p>{{ text }}</p>
        <div>
          <span class="pageBtn finger btnSure" @click="surePost">确定</span>
          <span class="pageBtn finger btnCancle" @click="postStatus = false">取消</span>
        </div>
      </div>
    </Modal>
    <!--    转调拨单弹窗 -->
    <Modal v-model="allocation" label-position="left" width="400" :footer-hide="true" :closable="false" class-name="vertical-center-modal">
      <div class="reviewDiv">
        <!-- <p style="text-align: left; margin: 5px 0px;">请选择调拨类型!</p>
        <div class="radioSelect">
          <RadioGroup v-model="allocationS">
            <Radio label="1">
              <span>同价</span>
            </Radio>
            <Radio label="2">
              <span>变价</span>
            </Radio>
          </RadioGroup>
        </div> -->
        <Form label-position="left" :label-width="120">
					<FormItem label="选择调拨类型:" >
            <RadioGroup class="radioClass" v-model="allotObj.transfer_type">
              <Radio label="1">
                <span>同价</span>
              </Radio>
              <Radio label="2">
                <span>变价</span>
              </Radio>
            </RadioGroup>
          </FormItem>
					<FormItem label="选择调拨仓库:" >
            <Select  v-model="allotObj.to_warehouse_id">
              <Option v-for="item in storeList" :value="item.warehouse_id" :key="item.warehouse_id">{{ item.warehouse_name }}</Option>
            </Select>
          </FormItem>
        </Form>
        <div>
          <span class="pageBtn finger btnSure" @click="allocationPost">确定</span>
          <span class="pageBtn finger btnCancle" @click="allocation = false">取消</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  name: 'salesApplicationEditConsign',
  computed: {
    allNum() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        // 0.1 + 0.2 精度问题解决
        totle = (totle * 10 + e.product_amount * 10) / 10
      })
      return totle
    },
    totleMoney() {
      let totle = 0
      this.meterList.forEach((e, i) => {
        totle = totle * 1 + e.product_total_price * 1
      })
      return totle.toFixed(2)
    },
  },
  data() {
    return {
      allocation: false,
      allotObj: {},
      type: '1', // 1是查看详情 2是编辑
      postStatus: false,
      text: '',
      meterList: [],
      listColumns: [
        {
          type: 'selection',
          width: 55,
          align: 'center',
        },
        {
          title: '序号',
          type: 'index',
          align: 'center',
          width: 60,
        },
        {
          title: '物料号',
          slot: 'product_model_code',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '物料号描述',
          key: 'product_model_code_desc',
          align: 'center',
          minWidth: 250,
          render: (h, param) => {
            return h('div', [
              h(
                'div',
                {
                  class: 'TextAlign_lf',
                },
                param.row.product_model_code_desc
              ),
            ])
          },
        },
        {
          title: '产品名称',
          key: 'product_name',
          align: 'center',
          minWidth: 250,
        },
        {
          title: '注册证号/备案凭证号',
          key: 'product_licence_code',
          align: 'center',
          minWidth: 200,
        },
        {
          title: '规格型号',
          key: 'product_model_name',
          align: 'center',
          width: 115,
        },
        {
          title: '货号/SKU',
          key: 'product_item_number',
          align: 'center',
          width: 115,
        },
        {
          title: '单价',
          slot: 'product_unit_price',
          align: 'center',
          width: 140,
        },
        {
          title: '销售税率',
          slot: 'sale_tax_rate',
          align: 'center',
          width: 140,
        },
        {
          title: '不含税价',
          key: 'product_after_tax_unit_price',
          align: 'center',
          width: 140,
          render: (h, param) => {
            return h('div', [h('span', (param.row.product_unit_price / (1 + param.row.sale_tax_rate / 100)).toFixed(4))])
          },
        },
        {
          title: '数量',
          slot: 'product_amount',
          align: 'center',
          width: 140,
        },
        {
          title: '医院单位',
          key: 'meins',
          align: 'center',
          width: 180,
        },
        {
          title: '库存余量',
          key: 'inventory',
          align: 'center',
          width: 100,
        },
        {
          title: '单位',
          key: 'product_unit',
          align: 'center',
          width: 85,
        },
        {
          title: '包装规格',
          key: 'product_packing_size',
          align: 'center',
          width: 114,
        },
        {
          title: '有效天数',
          key: 'valid_days',
          align: 'center',
          width: 140,
        },
        {
          title: '小计',
          align: 'center',
          width: 100,
          render: (h, param) => {
            return h('div', [h('span', param.row.product_total_price ? '¥' + param.row.product_total_price : '0')])
          },
        },
      ],
      storeList: [],
      postFrom: {
        apply_order_code: '',
        warehouse_id: '',
        product_info: [],
        remark: '',
        submit_type: '1', // 1转采购  2是转销售
      },
      selectList: [], // 勾选数据
      lastSelect: [],
      CustomerName: '',
    }
  },
  mounted() {
    this.queryStoreList()
    this.postFrom.apply_order_code = this.$route.query.id
    this.type = this.$route.query.type
    this.queryList()
  },
  methods: {
    allocationTrue() {
      this.lastSelect = []
      for (let i = 0; i < this.meterList.length; i++) {
        if (this.meterList[i]._checked) {
          this.lastSelect.push(this.meterList[i])
        }
      }
      if (!this.postFrom.warehouse_id) {
        this.$Message.warning(`请先选择仓库`)
        return
      }
      if (this.lastSelect.length == 0) {
        this.$Message.warning(`请至少选择一组产品`)
        return
      }
      // for (let m = 0; m < this.lastSelect.length; m++) {
      //   if (!this.meterList[m].product_unit_price) {
      //     this.$Message.warning(`单价不能填写为0，请修改为0.01`)
      //     return
      //   }
      // }
      this.allotObj = {}
      this.allocation = true
    },
    // 转调拨确认
    allocationPost() {
      if (!this.allotObj.transfer_type) {
        this.$Message.warning(`请先选择调拨类型`)
        return
      }
      if (!this.allotObj.to_warehouse_id) {
        this.$Message.warning(`请先选择仓库`)
        return
      }
      this.allocation = false
      Object.assign(this.postFrom, this.allotObj)
      this.post(3)
    },
    changeCode(e, row, index) {
      if (!this.postFrom.warehouse_id) {
        this.$Message.warning(`请先选择仓库`)
        return
      }
      if (!e) return
      this.$http.get(this.$apiConsign.saleApplyProductSearchByCode, { product_code_child: e, apply_detail_id: row.apply_detail_id, warehouse_id: this.postFrom.warehouse_id }, true).then(res => {
        let obj = Object.assign({}, row, res.data)
        obj.product_model_code = e
        this.$set(this.meterList, index, obj)
        for (let i = 0; i < this.selectList.length; i++) {
          for (let j = 0; j < this.meterList.length; j++) {
            if (this.selectList[i].apply_detail_id == this.meterList[j].apply_detail_id) {
              this.selectList[i].product_unit_price = this.meterList[j].product_unit_price || 1
              this.$set(this.meterList[j], '_checked', true)
            }
          }
        }
        this.meterList = JSON.parse(JSON.stringify(this.meterList))
      })
    },
    // 及时查询库存余量
    wareChange() {
      this.selectList = []
      this.queryList()
    },
    selectChange(list) {
      if (list.length == 0) {
        this.meterList.forEach((item, index) => {
          if (item._checked) {
            item._checked = false
          }
        })
      }
      this.selectList = list.length != 0 ? JSON.parse(JSON.stringify(list)) : []
      for (let i = 0; i < this.selectList.length; i++) {
        for (let j = 0; j < this.meterList.length; j++) {
          if (this.selectList[i].apply_detail_id == this.meterList[j].apply_detail_id) {
            this.selectList[i].product_unit_price = this.meterList[j].product_unit_price || 1
            this.$set(this.meterList[j], '_checked', true)
          }
        }
      }
      this.meterList = JSON.parse(JSON.stringify(this.meterList))
    },
    // 确认转化
    surePost() {
      this.postFrom.product_info = JSON.parse(JSON.stringify(this.lastSelect))
      this.$http.post(this.$apiConsign.saleApplyOperate, this.postFrom, true).then(res => {
        this.$Message.success(res.message)
        this.postStatus = false
        this.$router.go(-1)
      })
    },
    // 点击转化按钮
    post(num) {
      this.lastSelect = []
      for (let i = 0; i < this.meterList.length; i++) {
        if (this.meterList[i]._checked) {
          this.lastSelect.push(this.meterList[i])
        }
      }
      if (!this.postFrom.warehouse_id) {
        this.$Message.warning(`请先选择仓库`)
        return
      }
      if (this.lastSelect.length == 0) {
        this.$Message.warning(`请至少选择一组产品`)
        return
      }
      for (let i = 0; i < this.lastSelect.length; i++) {
        this.lastSelect[i].product_after_tax_unit_price = this.lastSelect[i].product_unit_price / (1 + this.lastSelect[i].sale_tax_rate / 100)
        if (!this.lastSelect[i].product_name) {
          this.$Message.warning(`请选择第${i + 1}组产品的物理号`)
          return
        }
        if (!this.lastSelect[i].product_amount) {
          this.$Message.warning(`请完善第${i + 1}组产品的单价或数量再提交`)
          return
        }
        if (num == 2 || num == 1) {
          if (!this.lastSelect[i].product_unit_price) {
            if (this.lastSelect[i].product_unit_price == 0) {
              this.$Message.warning(`单价不能填写为0，请修改为0.01`)
              return
            } else {
              this.$Message.warning(`请完善第${i + 1}组产品的单价再提交`)
              return
            }
          }
        } else {
          if (!this.lastSelect[i].product_unit_price && this.lastSelect[i].product_unit_price != 0) {
            this.$Message.warning(`请完善第${i + 1}组产品的单价再提交`)
            return
          }
        }
      }
      this.postFrom.submit_type = num
      if (num == 1) {
        this.text = '是否转为采购申请？'
        this.postStatus = true
        // sessionStorage.setItem('updataCache', '0')
      } else if (num == 2) {
        this.text = '是否转为销售订单？'
        this.postStatus = true
        // sessionStorage.setItem('updataCache', '0')
      } else if (num == 3) {
        this.surePost()
        // sessionStorage.setItem('updataCache', '0')
        return
        // this.text = '是否转为调拨单？'
        // sessionStorage.setItem('updataCache', '0')
      }
    },
    // 返回
    back() {
      this.$router.go(-1)
    },
    // 查询详情
    queryList() {
      this.$http.get(this.$apiConsign.saleApplyOperate, { apply_order_code: this.postFrom.apply_order_code, warehouse_id: this.postFrom.warehouse_id }, true).then(res => {
        this.postFrom.remark = res.data.remark
        // 客户名称
        this.CustomerName = res.data.customer_name
        this.meterList = res.data.apply_detail
        for (let i = 0; i < this.meterList.length; i++) {
          this.$set(this.meterList[i], 'product_total_price', this.meterList[i].product_amount * this.meterList[i].product_unit_price)
          this.$set(this.meterList[i], 'product_unit_price', this.meterList[i].product_unit_price ? this.meterList[i].product_unit_price : 0)
          if (this.meterList[i].product_code_child_array.length > 1) {
            this.meterList[i].product_model_code = ''
          }
          if (this.meterList[i].be_transfer == '1') {
            this.$set(this.meterList[i], '_disabled', true)
          }
        }
      })
    },
    // 查询仓库列表
    queryStoreList() {
      let data = {
        warehouse_name: '',
        page: 1,
        rows: 1000,
        is_consign:1
      }
      this.$http.get(this.$apiConsign.permissionWarehouseList, data, true).then(res => {
        this.storeList = res.data
      })
    },
    changeTotal(e, name, index) {
      this.$set(this.meterList[index], name, e * 1)
      let total = this.meterList[index].product_amount * this.meterList[index].product_unit_price
      this.$set(this.meterList[index], 'product_total_price', total > 0 ? total.toFixed(2) : 0)
      // for(let i = 0; i < this.selectList.length; i++) {
      //   for(let j = 0; j < this.meterList.length; j++) {
      //     if(this.selectList[i].apply_detail_id == this.meterList[j].apply_detail_id) {
      //       this.$set(this.selectList[j], 'product_unit_price', this.meterList[j].product_unit_price)
      //       this.$set(this.selectList[j], 'product_amount', this.meterList[j].product_amount)
      //     }
      //   }
      // }
    },
  },
}
</script>

<style scoped lang="less">
.pagePadding {
  display: flex;
  flex-direction: column;
  .pageTop {
    flex: 1;
    overflow-y: auto;
    .tipsDiv {
      margin-top: 20px;
      border: 1px solid #e8eaec;
      height: 230px;
      display: flex;
      .tipsFl {
        width: 210px;
        line-height: 230px;
        padding-left: 33px;
        border-right: 1px solid #e8eaec;
        background: #f8f8f9;
        color: #525b6d;
        font-size: 14px;
      }
      /deep/ .tipsFr {
        flex: 1;
        display: flex;
        padding: 8px 15px 8px 23px;
        .ivu-input-wrapper {
          height: 100% !important;
        }
        .ivu-input {
          height: 100% !important;
        }
      }
    }
  }
  .pageBtm {
    float: right;
    padding-top: 25px;
  }
}
.marginRight60 {
  margin-right: 60px;
}
.marginRight50 {
  margin-right: 55px;
}
.danjia {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.imgInfo {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    position: absolute;
    left: 0px;
    top: 0px;
  }
}
.pagePadding /deep/ .ivu-table td,
.ivu-table th {
  padding: 0px !important;
  position: relative !important;
}
// .pagePadding /deep/ .ivu-table-cell{
//   padding-left: 0px !important;
// }
.radioSelect {
  margin: 30px 0px;
}
.radioClass{
  display: flex;
  justify-content: space-around;
  /deep/.ivu-radio-group-item{
    font-size: 16px!important;
  }
}
</style>
